import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import Input from "../common/Input";
import Textarea from "../common/Textarea";
import Button from "../common/Button";
import CheckboxBtn from "../common/CheckboxBtn";
import FormInfoPopup from "../common/FormInfoPopup";
import RodoInfo from "../common/RodoInfo";
import useForm from "./useForm";
import { findItemFromArray, getErrorMessageByFieldName } from "../../utils/common";

const ContactForm = ({ lang, t }) => {
  const captchaRef = useRef();

  const [infoPopup, setInfoPopup] = useState("");
  const [values, updateValue, updateSubject, submitHandler, verifyCallback, errors] = useForm(
    {
      subject: [],
      name: "",
      email: "",
      phone: "",
      message: "",
      captcha: null
    },
    captchaRef,
    setInfoPopup,
    t
  );

  return (
    <form className="Contact__form" onSubmit={submitHandler}>
      <div className="Contact__form__columns">
        <div className="Contact__form__column">
          <Input
            name="name"
            type="text"
            value={values.name}
            onChange={updateValue}
            labelText={t.name.placeholder}
            maxLength={255}
            errorMessage={getErrorMessageByFieldName(errors, "name")}
          />
          <Input
            name="email"
            type="email"
            value={values.email}
            onChange={updateValue}
            labelText={t.email.placeholder}
            maxLength={255}
            errorMessage={getErrorMessageByFieldName(errors, "email")}
          />
          <Input
            name="phone"
            type="text"
            value={values.phone}
            onChange={updateValue}
            labelText={t.phone.placeholder}
            maxLength={16}
            errorMessage={getErrorMessageByFieldName(errors, "phone")}
          />
          <Textarea
            name="message"
            value={values.message}
            onChange={updateValue}
            rows={10}
            labelText={t.message.placeholder}
            maxLength={500}
            errorMessage={getErrorMessageByFieldName(errors, "message")}
          />
        </div>

        <div className="Contact__form__column">
          <p className="small-title small-title--withMargin">{t.subject.title}</p>

          <div className="Contact__form__checkboxBtns">
            {t.subject.list.map((elem, i) => (
              <CheckboxBtn key={i} name={elem.name} checked={!!findItemFromArray(values.subject, elem.name)} onChange={updateSubject} labelText={elem.label} />
            ))}
          </div>
          {getErrorMessageByFieldName(errors, "subject") && <p className="error">{getErrorMessageByFieldName(errors, "subject")}</p>}
        </div>
      </div>

      <div className="ReCAPTCHA">
        <ReCAPTCHA ref={captchaRef} sitekey={process.env.RECAPTCHA_PUBLIC_KEY} onChange={verifyCallback} hl={lang} />
        {getErrorMessageByFieldName(errors, "captcha") && <p className="error">{getErrorMessageByFieldName(errors, "captcha")}</p>}
      </div>

      <Button>{t.submit}</Button>

      <RodoInfo t={t.rodoInfo} />

      {infoPopup && <FormInfoPopup info={infoPopup} close={() => setInfoPopup("")} />}
    </form>
  );
};

ContactForm.propTypes = {
  lang: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired
};

export default ContactForm;
