import { useState } from "react";
import FormService from "../../services/FormService";
import { isName, isEmail } from "../../utils/validation";
import { removeItemFromArray } from "../../utils/common";

const useForm = (initial, captchaRef, setInfoPopup, t) => {
  const [values, setValues] = useState(initial);
  const [errors, setErrors] = useState([]);

  const updateValue = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const updateSubject = ({ target }) => {
    const { name, checked } = target;
    let subject = [];

    if (checked) subject = [...values.subject, name];
    else subject = removeItemFromArray(values.subject, name);

    setValues({ ...values, subject });
  };

  const resetCaptcha = () => {
    setValues({ ...values, captcha: null });
    captchaRef.current.reset();
  };

  const verifyCallback = response => {
    setValues({ ...values, captcha: response });
  };

  const validate = values => {
    const errorsList = [];

    if (!values.name) errorsList.push({ fieldName: "name", errorMessage: t.name.errors[0] });
    else if (!isName(values.name)) errorsList.push({ fieldName: "name", errorMessage: t.name.errors[1] });
    if (!values.email) errorsList.push({ fieldName: "email", errorMessage: t.email.errors[0] });
    else if (!isEmail(values.email)) errorsList.push({ fieldName: "email", errorMessage: t.email.errors[1] });
    if (!values.message) errorsList.push({ fieldName: "message", errorMessage: t.message.errors[0] });
    if (!values.subject.length) errorsList.push({ fieldName: "subject", errorMessage: t.subject.errors[0] });
    if (!captchaRef.current.getValue()) errorsList.push({ fieldName: "captcha", errorMessage: t.captcha.errors[0] });

    return errorsList;
  };

  const submitHandler = async e => {
    e.preventDefault();

    if (validate(values).length > 0) {
      resetCaptcha();
      return setErrors(validate(values));
    }
    if (errors.length > 0) setErrors([]);

    try {
      const response = await FormService.sendContactForm({ currentData: values });
      if (!!response.success) setInfoPopup(t.success);
      else throw response;

      resetCaptcha();
      setValues(initial);
    } catch (err) {
      setInfoPopup(t.error);
    }
  };

  return [values, updateValue, updateSubject, submitHandler, verifyCallback, errors];
};

export default useForm;
