import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Layout from "../../layouts/Layout";
import Seo from "../seo";
import Intro from "../common/Intro";
import Breadcrumb from "../common/Breadcrumb";
import ContactForm from "./ContactForm";
import { getShareImage } from "../../utils/getShareImage";

const Contact = props => {
  const { lang, t, layoutT, servicesT, postsT } = props.pageContext;
  const { pathname } = props.location;

  const [phone, setPhone] = useState("");

  useEffect(() => {
    setPhone(process.env.PHONE);
  }, [setPhone]);

  return (
    <Layout t={layoutT} servicesT={servicesT} posts={postsT} lang={lang} pathname={pathname}>
      <Seo title={t.seo.title} lang={lang} description={t.seo.description} image={getShareImage(lang)} />

      <Intro title={t.intro.title} />

      <Breadcrumb lang={lang} actualPage={t.seo.title} />

      <div className="Contact">
        <div className="container">
          <div className="Contact__description">
            <p className="small-title">{t.description}</p>
            <p className="small-title">{t.contactUs}</p>
          </div>

          <div className="Contact__data">
            <a href={`mailto:${t.email}`}>{t.email}</a>
            {lang === "pl" && !!phone && <a href={`tel:${phone}`}>{phone}</a>}
          </div>

          <ContactForm lang={lang} t={t.form} />
        </div>
      </div>
    </Layout>
  );
};

Contact.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Contact;
