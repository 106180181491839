import React from "react";
import PropTypes from "prop-types";

const CheckboxBtn = ({ labelText, ...attributes }) => (
  <div className="CheckboxBtn">
    <label className="CheckboxBtn__label">
      <input type="checkbox" {...attributes} />
      <span className="checkmark">{labelText}</span>
    </label>
  </div>
);

CheckboxBtn.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  labelText: PropTypes.string.isRequired
};

export default CheckboxBtn;
